import * as React from 'react'
import { useState } from 'react'

import { renderers } from '@markdoc/markdoc'
import type { HeadersFunction, LoaderFunctionArgs } from '@remix-run/node'
import { json } from '@remix-run/node'
import { Outlet, useLoaderData } from '@remix-run/react'
import cn from 'classnames'

import Details from '@/components/content/Details'
import SiteFooter from '@/components/SiteFooter'
import SiteHeader from '@/components/SiteHeader'
import { cacheControl, httpCacheControl } from '@/http.server'
import { findLp } from '@/models/lp.server'
import { pageHelper } from '@/request'

type LoaderData = typeof loader

export async function loader({ request }: LoaderFunctionArgs) {
  const lp = findLp('docs')

  if (!lp) {
    throw new Response('Not Found', { status: 404 })
  }

  const { root } = pageHelper(request)

  return json(
    {
      lp: lp,
      root: root
    },
    {
      headers: {
        'Cache-Control': cacheControl
      }
    }
  )
}

export const headers: HeadersFunction = () => ({
  'Cache-Control': httpCacheControl
})

export default function Docs() {
  const { lp } = useLoaderData<LoaderData>()
  const [isSidebarOpen, setIsSidebarOpen] = useState(false)

  return (
    <>
      <SiteHeader />

      <main className='container my-6 flex max-w-7xl flex-col px-3 sm:px-5 lg:px-12'>
        <section
          className={cn('', {
            '-mx-5 bg-gray-100/40 px-5 lg:!bg-primary dark:bg-gray-800/50': isSidebarOpen
          })}
        >
          <div className='flex lg:flex-row-reverse lg:gap-x-6'>
            {lp.aside && (
              <div>
                <button
                  onClick={() => setIsSidebarOpen(!isSidebarOpen)}
                  className={cn('absolute right-0 -mt-3 block bg-gray-100 px-sm lg:hidden dark:bg-gray-800', {
                    'inset-x-0 w-full text-right': isSidebarOpen
                  })}
                >
                  <span className={cn({ inline: !isSidebarOpen, hidden: isSidebarOpen })}>Documentation «</span>
                  <span className={cn({ hidden: !isSidebarOpen, inline: isSidebarOpen })}>Close »</span>
                </button>
                <aside
                  className={cn('tree-nav w-full shrink-0 space-y-3 lg:w-36', {
                    'block pt-3': isSidebarOpen,
                    'hidden lg:block': !isSidebarOpen
                  })}
                >
                  {renderers.react(lp.aside, React, {
                    components: {
                      Details
                    }
                  })}
                </aside>
              </div>
            )}

            <article
              className={cn('min-w-0 flex-grow', {
                'hidden lg:block': isSidebarOpen
              })}
            >
              <Outlet />
            </article>
          </div>
        </section>
      </main>

      <SiteFooter />
    </>
  )
}
